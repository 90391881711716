@import "../../theme";
@import "../../mixins";

.user-list {
    @include unstyled();
    padding-bottom: 50px;
}

.user-item {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border;

    &__data {
        @include media($breakpoint1, d) {
            margin-right: auto;
        }

        @include media($breakpoint1) {
            display: flex;
            align-items: center;
            flex-basis: 50%;
            max-width: 50%;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &__avatar {
        display: none;

        @include media($breakpoint1) {
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
            width: 30px;
            flex-basis: 30px;
            max-width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @include media($breakpoint2) {
            width: 40px;
            height: 40px;
            flex-basis: 40px;
            max-width: 40px;
            margin-right: 15px;
        }
    }

    &__persona {
        &-name {
            @include font();
            font-size: 10px;
            color: $dark;
            margin-bottom: 4px;

            @include media($breakpoint1) {
                font-size: 12px;
            }

            @include media($breakpoint2) {
                font-size: 14px;
            }
        }

        &-position {
            font-size: 9px;
            color: $gray;

            @include media($breakpoint1) {
                font-size: 10px;
            }

            @include media($breakpoint2) {
                font-size: 12px;
            }
        }
    }

    &--not-number {
        background: $border;
        opacity: 0.5;
    }

    &__status {
        margin: 0 5px;

        &-number {
            margin-bottom: 4px;
            font-size: 10px;
            line-height: 120%;

            @include media($breakpoint1) {
                font-size: 12px;
            }

            @include media($breakpoint2) {
                font-size: 14px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &-button {
                background: transparent;
                border: none;
                outline: none;
                display: flex;
                cursor: pointer;
            }

            &-list {
                position: absolute;
                box-shadow: 0 0 8px rgba(12, 82, 164, 0.25);
                background: #ffffff;
                border-radius: 2px;
                padding: 6px;
            }

            &-list-item {
                width: 100%;
                text-align: left;
                margin: 0;
                background: none;
                border: 0;
                list-style-type: none;
                text-decoration: none;
                outline: none;
                border-radius: 0;
                display: block;
                padding: 6px;
                font-family: HelveticaNeueCyr;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.03em;
                color: #545c67;
                cursor: pointer;
                white-space: nowrap;

                &:focus {
                    color: #2657cd;
                }
            }
        }
    }

    @include media($breakpoint2) {
        padding: 15px;
    }
}

.empty-user-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    &__title {
        font-family: HelveticaNeueCyr;
        font-size: 14px;
        line-height: 17px;
        color: #99aaba;
        margin-bottom: 30px;
        text-align: center;
    }

    &__image {
        max-width: 145px;
        max-height: 145px;

        img {
            width: 100%;
            height: auto;
        }
    }
}
