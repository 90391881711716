@import "../../theme";
@import "../../mixins";

.alert {
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.03em;

    &-icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
    }

    &--success {
        color: $success;
    }

    &--danger {
        color: $red-light;
    }
}
