@import "../theme";
@import "media";

@mixin font-face($family, $path, $weight, $style) {
    @font-face {
        font-family: $family;
        src: url('#{$path}.eot');
        src: url('#{$path}.eot?#iefix') format('embedded-opentype'),
        url('#{$path}.woff') format('woff'),
        url('#{$path}.ttf') format('truetype'),
        url('#{$path}.svg#') format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

@mixin font($weightInit: Roman, $font: HelveticaNeueCyr, $style: normal) {
    font-family: $font;
    font-style: $style;

    $weight: map_get((
            Thin: 100,
            UltraLight: 200,
            Light: 300,
            Roman: normal,
            Medium: 500,
            Bold: bold,
            Heavy: 800,
            'Black': 900
    ), $weightInit);

    font-weight: $weight or $weightInit;

}

