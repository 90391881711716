.right__block {
    display: block;
    width: 100%;
    padding: 0;
}

.big-loader {
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.big-loader .right__h1 {
    text-align: center;
    padding: 0;
}

.right_admin .flex-form .loadingProgress {
    margin: 0 20px;
}

.big-loader .loadingProgress{
    margin: auto;
    width: 200px;
    height: 10px;
    border-radius: 10px;
}

.big-loader .loadingProgressG {
    width: 47px;
    height: 10px;
    border-radius: inherit;
    margin-left: -47px;
    animation-name: bounce_loadingProgressGbig;
    -o-animation-name: bounce_loadingProgressGbig;
    -ms-animation-name: bounce_loadingProgressGbig;
    -webkit-animation-name: bounce_loadingProgressGbig;
    -moz-animation-name: bounce_loadingProgressGbig;
}

.right {
    height: 100%;
    position: relative;
}

@keyframes bounce_loadingProgressGbig {
    0% {
        margin-left: -47px;
    }
    100% {
        margin-left: 200px;
    }
}

@-webkit-keyframes bounce_loadingProgressGbig {
    0% {
        margin-left: -47px;
    }
    100% {
        margin-left: 200px;
    }
}
