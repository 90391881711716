@import "../../theme";
@import "../../mixins";

.tabs {
    @include unstyled();
    display: flex;
    padding: 0;
    background: $border;

    &-link {
        @include unstyled();
        padding: 12px 20px 10px;
        min-width: 135px;
        text-align: center;
        @include font();
        font-size: 12px;
        line-height: 1;
        color: $dark;
        outline: none;
        border: 1px solid $border;
        background: $border;
        white-space: nowrap;

        &.active {
            position: relative;
            background: $white;

        }

        &:hover, &:focus {
            outline: none;
        }
    }
}
