$border-radius: 2px;
$border-radius-input: 2px;
$border-radius-btn: 50px;

$breakpoint1: 380;
$breakpoint2: 550;

$white: #ffffff;
$black: #000000;
$orange: #F5841A;

$dark: #545C67;
$gray: #99AABA;
$border: #E4EBF2;

$blue-dark: #2657CD;
$blue-light: #316BF6;

$success: #80C925;
$success-light: #91E42B;

$red-dark: #9D0F23;
$red-light: #E90033;