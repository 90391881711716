@import "../theme";
@import "../mixins";

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    @include unstyled();
    border-radius: $border-radius-btn;
    padding: 0 10px;
    @include font(Bold);
    font-size: 10px;
    //line-height: 13px;
    min-height: 35px;
    text-transform: uppercase;
    color: $white;
    transition: 0.2s;
    cursor: pointer;

    > span {
        padding: 8px 0 6px;
    }

    @include media($breakpoint1) {
        font-size: 12px;
        //line-height: 15px;
        padding: 0 15px;
    }

    @include media($breakpoint2) {
        font-size: 14px;
        //line-height: 19px;
        padding: 0 20px;
    }

    &:focus {
        outline: none;
    }

    &--primary {
        background: $blue-dark;

        &:hover,
        &:focus {
            background: $blue-light;
        }
    }

    &--danger {
        background: $red-dark;

        &:hover {
            background: $red-light;
        }

        &:focus,
        &:active {
            background-color: #851625;
        }
    }

    &--success {
        background: $success;

        &:hover,
        &:focus {
            background: $success-light;
        }
    }

    &--secondary {
        @include font();
        color: $blue-dark;
        background: $white;
        font-size: 10px;
        border: 1px solid $border;
        text-transform: none;
        padding: 4px 8px;

        &:hover,
        &:focus {
            background: $blue-dark;
            color: $white;
            border-color: $blue-dark;
        }

        @include media($breakpoint1) {
            font-size: 12px;
            padding: 4px 10px;
        }

        @include media($breakpoint2) {
            font-size: 14px;
            padding: 4px 12px;
        }
    }

    &--with-icon {
        display: flex;
        align-items: center;

        .icon {
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &--icon {
        @include unstyled();
        color: $gray;
        width: 12px;
        height: 12px;

        &.is-favorite {
            color: $blue-dark;
            cursor: pointer;
        }
    }

    &--play,
    &--pause {
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        color: #2657cd;
        padding: 0;

        &:hover {
            color: #316bf6;
        }

        &:focus,
        &:active {
            color: #26499c;
        }

        &:disabled {
            color: #e4ebf2;
        }
    }
    &_gray {
        background-color: #ffffff;
        border: 1px solid #e4ebf2;
        box-sizing: border-box;
        border-radius: 50px;
        color: #99aaba;
        cursor: pointer;

        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 15px;

        &:disabled {
            cursor: auto;
            background-color: #e4ebf2;
            border: 1px solid #e4ebf2;
            box-sizing: border-box;
            border-radius: 50px;
        }
    }
}
