@mixin unstyled() {
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    list-style-type: none;
    text-decoration: none;
    outline: none;
    border-radius: 0;
}
