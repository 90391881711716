@import "../theme";
@import "../mixins";

body {
    @include font();
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1;
    color: $dark;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.hidden {
    display: none !important;
}

.btn-group {
    position: relative;
    display: flex;
    align-items: center;

    & > .btn {
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--fixed {
        position: fixed;
        padding: 10px;
        bottom: 0;
        width: 100%;
        background: $white;
        box-shadow: 0 0 5px $white;
        border-top: 1px solid #e4ebf2;
    }

    &--center {
        justify-content: center;
    }
}

.unstyled {
    @include unstyled();
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0 5px 10px rgba(12, 82, 164, 0.25);
    background: $white;
    border-radius: 2px;
    padding: 6px;

    @include media($breakpoint1) {
        right: auto;
        left: 0;
    }

    &:hover {
        display: block;
        z-index: 2;
    }
}

.dropdown-item {
    @include unstyled();
    display: block;
    padding: 6px;
    @include font();
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: $dark;
    cursor: pointer;
    white-space: nowrap;

    &:hover,
    &:focus {
        color: $blue-dark;
    }
}

.dropdown-toggle {
    &:hover + .dropdown-menu {
        display: block;
        z-index: 2;
    }
}

.newPhoneInput,
.newPhoneInput.focus,
.newPhoneInput:active {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e4ebf2;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 10px 14px 6px;
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    color: #99aaba;
    outline: none;
}

@import "fonts";
@import "status";
@import "btn";
@import "close";
@import "icons";
