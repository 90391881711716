@import "../theme";
@import "../mixins";

.status {
    @include font();
    font-size: 12px;
    color: inherit;
    display: flex;
    align-items: baseline;

    &::before {
        content: '';
        position: relative;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        color: inherit;
        background: currentColor;
        margin-right: 5px;
    }

    &--online {
        color: $success;
    }

    &--busy {
        color: $orange;
    }

    &--offline {
        color: $gray;
    }
}