@import "../../theme";
@import "../../mixins";

.form {
    margin: 20px auto 0;
    padding: 10px 10px 52px;

    max-width: 363px;
    @include media($breakpoint2) {
        max-width: 420px;
        padding-left: 100px;
    }
    }

.form-group {
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }
}

.form-input {
    margin-bottom: 8px;
    padding-top: 16px;
    position: relative;

    @include media($breakpoint2) {
        padding: 0;
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__control {
        @include media($breakpoint2) {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    &__label {
        @include unstyled();
        @include font();
        position: absolute;
        font-size: 12px;
        color: $gray;
        transition: .3s;
        pointer-events: none;
        white-space: nowrap;

        @include media($breakpoint1) {
            font-size: 14px;
        }

        @include media($breakpoint2, down) {
            padding-bottom: 4px;
            left: 16px;
            top: 27px;
        }

        @include media($breakpoint2) {
            position: absolute;
            top: 10px;
            left: auto;
            right: 100%;
            text-align: right;
            margin-right: 20px;
        }
    }

    &__input {
        width: 100%;
        max-width: 100%;
        @include font();
        font-size: 12px;
        line-height: 15px;
        color: $dark;
        border: 1px solid $border;
        padding: 8px 15px 6px;
        border-radius: $border-radius-input;
        resize: none;



        @include media($breakpoint1) {
            font-size: 14px;
        }

        @include media($breakpoint2) {
            max-width: 320px;
        }

        &::placeholder {
            color: $gray;
        }

        &:focus {
            outline: none;
            border-color: $blue-dark;
            box-shadow: 0 0 8px rgba(12, 82, 164, 0.25);

            ~ .form-input__label {
                color: $blue-dark;
            }
        }
    }

    &--not-empty {
        .form-input__label {
            @include media($breakpoint2, down) {
                left: 0;
                top: 0;
                font-size: 10px;

                @include media($breakpoint1) {
                    font-size: 12px;
                }
            }
        }
    }
}

.form-search {
    display: flex;
    align-items: center;
    position: relative;

    &__controls {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;

        & > * {
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__input {
        width: 100%;
        max-width: 100%;
        border: 1px solid $border;
        @include font();
        font-size: 12px;
        color: $dark;
        padding: 15px;

        &::placeholder {
            color: $gray;
        }

        &:focus {
            outline: none;
            border-color: $dark;
        }
    }
}
