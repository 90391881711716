@import "../mixins/font";

$fonts: (
                (Thin, 100, normal),
                (UltraLight, 200, normal),
                (Roman, normal, normal),
                (Bold, bold, normal),
                (Light, 300, normal),
                (Medium, 500, normal),
                (Heavy, 800, normal),
                ('black', 900, normal),

);

@each $font, $weight, $style in $fonts {
    @include font-face('HelveticaNeueCyr', '/static/fonts/HelveticaNeueCyr/HelveticaNeueCyr-#{$font}', $weight, $style);
}
