@import "../theme";
@import "../mixins";

svg {
    fill: currentColor;
}

.icon {
    color: $gray;
    width: 16px;
    height: 16px;
}

*:hover > .icon,
*:focus > .icon,
.icon:hover,
.icon:focus {
    color: $dark;
}

.icon.inherit-color,
*:hover > .icon.inherit-color,
*:focus > .icon.inherit-color,
.icon.inherit-color:hover,
.icon.inherit-color:focus {
    color: inherit;
}

.icon.inherit-size {
    width: 100%;
    height: 100%;
}
